
/* Estilos personalizados para el sweetalert2  -
 cada modal tiene su propio style*/

 .swal2-popup, .swal2-modal, .swal2-show {
  border-radius: 12px;
  max-width: 328px;
}


// modals modulo de picking

.swal2-close {
  color: #0A806A;

  &:hover {
    color: #0A806A;
  }
}

.c-swal2__cancel-attention {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 40px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    line-height: 32px !important;
  
    .text {
      margin-bottom: 24px !important;
    }
  
    .options {
      display: flex;
      flex-direction: column;
      
  
      &__item {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #F5F5F5;
        padding: 8px 0px;
  
        label {
          margin-left: 18px;
        }
      }
    }
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    margin-bottom: 8px !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }
  
  .c-swal2__button__cancel {
    outline: none;
    background-color: #f4fcfb !important;
    border: 1px solid #2ead99 !important;
    color: #0A806A !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }
  
}

.c-swal2__token-invalid {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }
}

.c-swal2__token-valid {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 40px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }
}

.c-swal2__resend-token {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    margin-bottom: 0 !important;
    line-height: 24px !important;
    margin: 0 16px 0 16px;
    
  
    .text {
      margin-bottom: 24px !important;
    }
  
    .options {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      padding: 0 16px;
  
      &__item {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #F5F5F5;
        padding: 8px 0px;
  
        label {
          margin-left: 18px;
        }
      }
    }
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    margin-bottom: 8px !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }

}

// fin modals modulo de picking


// modals modulo de customer

.c-swal2__new-token {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }

  &--rp {
    .swal2-close {
      color: #7125EE !important;
    
      &:hover {
        color: #7125EE !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #7125EE;
        }
      }
    }

    .c-swal2__title {
      color: #7125EE !important;
    }

    .c-swal2__button__confirm {
      background-color: #7125EE !important;
      border: 1px solid #7125EE !important;
    }
  }

  &--tpsa {
    .swal2-close {
      color: #FB3449 !important;
    
      &:hover {
        color: #FB3449 !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #FB3449;
        }
      }
    }

    .c-swal2__title {
      color: #FB3449 !important;
    }

    .c-swal2__button__confirm {
      background-color: #FB3449 !important;
      border: 1px solid #FB3449 !important;
    }
  }

  &--hpsa, &--hesa {
    .swal2-close {
      color: #FF6E00 !important;
    
      &:hover {
        color: #FF6E00 !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #FF6E00;
        }
      }
    }

    .c-swal2__title {
      color: #FF6E00 !important;
    }

    .c-swal2__button__confirm {
      background-color: #FF6E00 !important;
      border: 1px solid #FF6E00 !important;
    }
  }

  &--spsa {
    .swal2-close {
      color: #F1141E !important;
    
      &:hover {
        color: #F1141E !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #F1141E;
        }
      }
    }

    .c-swal2__title {
      color: #F1141E !important;
    }

    .c-swal2__button__confirm {
      background-color: #F1141E !important;
      border: 1px solid #F1141E !important;
    }
  }
}

.c-swal2__new-token-error {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 auto !important;
    width: 100% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  }

  &--rp {
    .swal2-close {
      color: #7125EE !important;
    
      &:hover {
        color: #7125EE !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #7125EE;
        }
      }
    }

    .c-swal2__title {
      color: #7125EE !important;
    }

    .c-swal2__button__confirm {
      background-color: #7125EE !important;
      border: 1px solid #7125EE !important;
    }
  }

  &--tpsa {
    .swal2-close {
      color: #FB3449 !important;
    
      &:hover {
        color: #FB3449 !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #FB3449;
        }
      }
    }

    .c-swal2__title {
      color: #FB3449 !important;
    }

    .c-swal2__button__confirm {
      background-color: #FB3449 !important;
      border: 1px solid #FB3449 !important;
    }
  }

  &--hpsa, &--hesa {
    .swal2-close {
      color: #FF6E00 !important;
    
      &:hover {
        color: #FF6E00 !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #FF6E00;
        }
      }
    }

    .c-swal2__title {
      color: #FF6E00 !important;
    }

    .c-swal2__button__confirm {
      background-color: #FF6E00 !important;
      border: 1px solid #FF6E00 !important;
    }
  }

  &--spsa {
    .swal2-close {
      color: #F1141E !important;
    
      &:hover {
        color: #F1141E !important;
      }
    }

    .c-swal2__icon {
      .swal2-icon-content {
        path {
          fill: #F1141E;
        }
      }
    }

    .c-swal2__title {
      color: #F1141E !important;
    }

    .c-swal2__button__confirm {
      background-color: #F1141E !important;
      border: 1px solid #F1141E !important;
    }
  }
}
// fin modals modulo de customer


// modals modulo de monitoring

.c-swal2__logout-session {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: .875rem !important;
    border-radius: 8px !important;
    // margin: 0 auto !important;
    width: 54% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
    padding: 0;
  
    &:focus {
      box-shadow: none !important;
    }
  }

  .c-swal2__button__cancel {
    outline: none;
    // margin: 0 auto !important;
    width: 46% !important;
    height: 48px !important;
    padding: 0;
    border-radius: 8px !important;
    border: 1px solid #0A806A !important;
    background: #F4FCFB !important;
    color: #0A806A;
    font-family: Nunito;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 1.25px;
    text-transform: uppercase;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.c-swal2__download-report {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0a806a !important;
    color: #0a806a !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0a806a !important;
    border: 1px solid #0a806a !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: .75rem !important;
    border-radius: 8px !important;
    width: 57% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
    padding: 0;
  
    &:focus {
      box-shadow: none !important;
    }
  }

  .c-swal2__button__cancel {
    outline: none;
    width: 43% !important;
    height: 48px !important;
    padding: 0;
    border-radius: 8px !important;
    border: 1px solid #0A806A !important;
    background: #F4FCFB !important;
    color: #0A806A;
    font-family: Nunito;
    font-size: .75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 1.25px;
    text-transform: uppercase;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.c-swal2__primary {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #0A806A !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #0A806A !important;
    color: #0A806A !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #0A806A !important;
    border: 1px solid #0A806A !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: .875rem !important;
    border-radius: 8px !important;
    // margin: 0 auto !important;
    width: 54% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
    padding: 0;
  
    &:focus {
      box-shadow: none !important;
    }
  }

  .c-swal2__button__cancel {
    outline: none;
    // margin: 0 auto !important;
    width: 46% !important;
    height: 48px !important;
    padding: 0;
    border-radius: 8px !important;
    border: 1px solid #0A806A !important;
    background: #F4FCFB !important;
    color: #0A806A;
    font-family: Nunito;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 1.25px;
    text-transform: uppercase;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.c-swal2__warn {

  .c-swal2__title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #F2536F !important;
    padding-top: 0 !important;
    line-height: 32px !important;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
  
    &--not-icon {
      margin-top: 72px !important;
      padding: 0 30px;
    }
  }
  
  .c-swal2__icon {
    margin-top: 72px !important;
    border-color: #F2536F !important;
    color: #F2536F !important;
    height: 48px !important;
    width: 48px !important;
    margin-bottom: 24px !important;
  
    .swal2-icon-content {
      font-size: 2.75em !important;
    }
  }
  
  div:where(.swal2-icon) {
    border: 0 !important;
  }

  .swal2__text {
    color: #393939;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  .c-swal2__html-container {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #393939 !important;
    margin-top: 0px !important;
    line-height: 24px !important;
    margin: 0 16px 40px 16px !important;
  }
  
  .c-swal2__container {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #0A806A !important;
    margin: 0 16px 40px !important;
    line-height: 32px !important;
  }
  
  .c-swal2__popup {
    padding-bottom: 40px !important;
  }
  
  .c-swal2__actions {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin: 0 16px 0;
  }
  
  .c-swal2__button__confirm {
    outline: none;
    background-color: #F2536F !important;
    border: 1px solid #F2536F !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: .875rem !important;
    border-radius: 8px !important;
    // margin: 0 auto !important;
    width: 54% !important;
    letter-spacing: 1.25px !important;
    height: 48px !important;
    padding: 0;
  
    &:focus {
      box-shadow: none !important;
    }
  }

  .c-swal2__button__cancel {
    outline: none;
    // margin: 0 auto !important;
    width: 46% !important;
    height: 48px !important;
    padding: 0;
    border-radius: 8px !important;
    border: 1px solid #0A806A !important;
    background: #F4FCFB !important;
    color: #0A806A;
    font-family: Nunito;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 1.25px;
    text-transform: uppercase;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// fin modals modulo de monitoring


