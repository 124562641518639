@import 'theme';

html {
  font-size: 16px;
}

html, body { height: 100%; }
body {
  margin: 0;
  background-color: var(--aria-background-color-2);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #fcfcfc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #818181;
}

input, button {
  font-family: 'Nunito';
}


/* Estilos personalizados para el tooltip */
.mdc-tooltip__surface {
  padding: 12px !important;
  background: rgba(7, 7, 7, 0.70) !important;
  color: #FFF  !important;
  font-size: 12px  !important;
  font-style: normal  !important;
  font-weight: 400  !important;
  line-height: 14px  !important;
  border-radius: 12px !important;
  max-width: 240px !important;
}

///// falta modificar ////

.topbar__search {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.search-box {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.filter {
  .mat-select  {
    font-family: Nunito !important;
  }

  .mat-select-panel {
    font-family: Nunito !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.ranking-detail__filter {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.store__filter {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

//Sizes in mat-input-date-range
.mat-date-range-input-container {
  width: 100%!important;
}

.topbar__tenant {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.mat-select-panel .mat-option  {
  font-family: Nunito !important;
  font-size: .875rem !important;
}


// estilos personalizados exclusivos para monitoring module
.monitoring {

  &__section {
    background-color: #FFF;
    padding: 1rem 1rem;
    border-radius: 20px;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  // mat-form-field {
  //   width: 100%;
  // }

  mat-select {
    font-family: Nunito;
    font-size: .875rem;
  }

  mat-label {
    font-family: Nunito;
    font-size: .875rem;
  }

  mat-date-range-input {
    display: flex;
    font-family: Nunito;
    font-size: .875rem;
  }

  mat-datepicker-toggle {
    margin-left: 0.5rem;
  }

  .mat-mdc-form-field {
    margin-bottom: 0;
    width: 100%;

    .mdc-notched-outline__leading {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
    }
    .mdc-notched-outline__trailing {
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: var(--rita-black-color-800);
    }
    &.mat-focused {
        .mat-mdc-floating-label, .mat-mdc-select-arrow {
        color: var(--rita-primary-color-700) !important;
        }
        .mdc-notched-outline__notch,
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
        border-width: 1px !important;
        border-color: var(--rita-primary-color-700) !important;
        }
    }
    &.ng-valid {
        .mat-mdc-floating-label {
        color: var(--rita-primary-color-700) !important;
        }
        .mdc-notched-outline__notch,
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
        border-width: 1px !important;
        border-color: var(--rita-primary-color-700) !important;
        }
    }
    &.mat-mdc-form-field-has-icon-prefix {
        //@extend %mat-form-field-icon-prefix-styles;
    }
    &.is-invalid, &.ng-invalid {
        &.mat-form-field-hide-placeholder{
            .mat-mdc-floating-label {
                color: #B6B7B7 !important;
            }
        }
        .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix{
            color: var(--rita-warn-color-350) !important;
        }
        &.ng-dirty{
            &.mat-form-field-hide-placeholder{
                .mat-mdc-floating-label {
                    color: var(--rita-black-color-800) !important;
                }
            }
            .mat-mdc-floating-label {
                color: var(--rita-warn-color-350) !important;
            }
            .mdc-notched-outline__notch,
            .mdc-notched-outline__leading,
            .mdc-notched-outline__trailing {
                border-width: 1px !important;
                border-color: var(--rita-warn-color-350) !important;
            }
        }
    }
  }

  .ranking-detail__table__footer, .store__table__footer, .search__list__footer {
    .mat-mdc-form-field-infix {
      min-height: 1.875rem;
      max-height: 1.875rem;
      padding: .1875rem 0 !important; // Ajusta según tu necesidad
    }

    .mat-mdc-select-value {
      font-size: .75rem !important;
    }

    // .mat-mdc-select-trigger {
    //   padding: 0 0 !important;
    // }

    .mat-mdc-form-field-wrapper {
      padding: 0 !important;// Quita padding extra
    }

    /* Ajustar el espacio donde aparece el valor seleccionado */
    .mat-mdc-select-trigger {
      // line-height: 1.2 !important;      // Disminuye el espacio vertical
      // font-size: 0.9rem !important;     // Tamaño de fuente más pequeño
      // padding: 4px 0 !important;         // Ajusta el espacio interno
      // min-height: auto !important;     // Quita altura mínima
    }

    /* Opcional: Ajusta también la altura o estilo del label flotante */
    .mat-mdc-form-field-label-wrapper {
      margin: 0;
    }
  }

  .mat-mdc-header-row {
    height: 2.9375rem !important;
  }
}



