::ng-deep {
    .mat-mdc-select-panel {
        padding: 0 !important;
        border-radius: 12px !important;
        .mat-mdc-option {
            color: #B6B7B7 !important;
            font-size: 14px !important;
            &.mdc-list-item--selected{
                font-weight: 600;
            }
        }
        .mat-mdc-option-active {
            .mat-pseudo-checkbox {
                display: none !important;
            }
        }
    }
}


.mat-mdc-select-arrow-wrapper {
  .mat-mdc-select-arrow {
    display: none;
  }

  &::after {
    content: "expand_more";
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 16px;
    margin-right: -3px;
    padding-left: 5px;
    color: var(--rex-accent-color-300);
  }
}


.mat-mdc-form-field
.mat-mdc-select.mat-mdc-select-disabled
.mat-mdc-select-arrow-wrapper
{
  .mat-mdc-select-arrow {
    display: none;
  }
  &::after{
    content: "lock";
    display: inline-block;
    font-family: 'Material Icons Outlined';
    font-size: 16px;
    margin-right: -3px;
    padding-left: 5px;
    color: #B6B7B7;
  }
}


