@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '.../../assets/scss/swal2.custom.scss';
@import '.../../assets/scss/accordion.material.scss';
@import '.../../assets/scss/checkbox.material.scss';
@import '.../../assets/scss/snack-bar.material.scss';
@import '.../../assets/scss/select.material.scss';
@import 'mixins';
@include mat-core();

$rita-warn: (
  50 : #fdebeb,
  100 : #f9cccc,
  200 : #f6abab,
  300 : #f28989,
  350: #F2536F,
  400 : #ef6f6f,
  500 : #ec5656,
  600 : #ea4f4f,
  700 : #e74545,
  800 : #e43c3c,
  900 : #df2b2b,
  A100 : #ffffff,
  A200 : #ffebeb,
  A400 : #ffb8b8,
  A700 : #ff9f9f,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$rita-accent: (
  50 : #fcfcfc,
  100 : #f7f7f7,
  200 : #f2f2f2,
  300 : #ececec,
  400 : #cbcbcb,
  500 : #adadad,
  600 : #838383,
  700 : #6e6e6e,
  800 : #4e4e4e,
  900 : #2c2c2c,
  A100 : #838383,
  A200 : #838383,
  A400 : #838383,
  A700 : #838383,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$rita-primary: (
  50 : #e1f3f1,
  100 : #b4e2da,
  200 : #83cfc3,
  300 : #52bcab,
  400 : #2ead99,
  500 : #2ead99,
  600 : #0e907a,
  700 : #0a806a,
  800 : #05705c,
  900 : #005440,
  A100 : #bdfff0,
  A200 : #8affe4,
  A400 : #57ffd9,
  A700 : #3dffd3,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$rita-black: (
  50: #F7F7F7,
  100: #EEEEEE,
  200: #E2E2E2,
  300: #D0D0D0,
  400: #ABABAB,
  500: #8A8A8A,
  600: #636363,
  700: #505050,
  800: #323232,
  900: #000000,
);

// Semantic colors
$rita-semantic: (
  'red': map-get($rita-warn, 500),
  red-dark: #742A2A,
  red-light: #FAD5D5,
  'orange': #EC8E4B,
  orange-dark: #744525,
  orange-light: #FAE3D2,
  'yellow': #F8C953,
  yellow-dark: #7A6329,
  yellow-light: #FDF1D4,
  'green': #51D36E,
  green-dark: #317F42,
  green-light: #B9EDC5,
  green-light-2: #81DCB0,
  green-light-3: #E3F1EA,
  green-light-4: #2BA168,
  green-light-5: #FEFCFC99,
  green-light-6: #00B8A2,
  green-light-7: #E4F2F0,
  green-light-8: #13543A,
  green-light-9: #F6FFFE,
  'blue': #459FE0,
  blue-dark: #224E6E,
  blue-light: map-get($rita-accent, 900),
  'purple': #A067E8,
  purple-dark: #4E3271,
  purple-light: #E7D9F9,
  'pink': #E74DBC,
  pink-dark: #71265C,
  pink-dark-2: #AA279D,
  pink-light: #F9D3EE,
  pink-light-2: #FDEAFB,
);

$rita-system: (
  primary: map-get($rita-primary, 700),
  accent: map-get($rita-accent, 500),
  warning: map-get($rita-warn, 500),
  success: #43A047,
  alert: map-get($rita-semantic, 'yellow'),
);

// Background colors
$rita-background: (
  1: #FFFFFF,
  2: #F7F9F6,
);

// Toastr colors
$rita-toastr: (
  red-1: map-get($rita-warn, 500),
  red-2: #F44336,
  red-3: #FFEBEE,
  yellow-1: #FF9800,
  yellow-2: #FFF3E0,
  green-1: map-get($rita-system, success),
  green-2: #E8F5E9,
  blue-1: #2196F3,
  blue-2: #E3F2FD,
);

// Misc colors
$rita-misc: (
  sky-blue: #EFF8FF,
  'green': #017466,
  green-with-opacity: #81dcb04d,
  red-4: #FFC5C5,
  red-5: #FDEBEA,
  red-6: #AA273C,
  yellow-1: #FFEDCA,
  yellow-2: #C78B1A,
  brown-1: #FFE2C8,
  brown-2: #AA6627,
  'blue': map-get($rita-accent, 900),
  blue-2: #EBF0FE,
  blue-3: #174F9A,
  button-microsoft: #2F2F2F,
);

$rita-tenant: (
  AGORA: #02B1FF,
  AGORA_CLUB: #F2007C,
  AGORA_PAY: #00CAFF,
  AGORA_SHOP: #FF6000,
  AGORA_AHORRA: #001FFE,
  MKR: #FFE500,
  TPSA: #FF4949,
  PVEA: #FF4949,
  HPSA: #F59237,
  HESA: #F59237,
  RPLAZA: #7B61FF,
  SHPSTAR: #0970CC,
  VIV: #168D7F
);

// Referencias de Colores
:root {
  @include generate-color-variables('primary', $rita-primary);
  @include generate-color-variables('accent', $rita-accent);
  @include generate-color-variables('warn', $rita-warn);
  @include generate-color-variables('black', $rita-black);
  @include generate-color-variables('semantic', $rita-semantic);
  @include generate-color-variables('system', $rita-system);
  @include generate-color-variables('background', $rita-background);
  @include generate-color-variables('toastr', $rita-toastr);
  @include generate-color-variables('misc', $rita-misc);
  @include generate-color-variables('tenant', $rita-tenant);

}

/* Typography */
$headline1: mat.define-typography-level(32px, 24px, 700);
$headline2: mat.define-typography-level(28px, 24px, 600);
$headline3: mat.define-typography-level(20px, 24px, 500);
$headline4: mat.define-typography-level(18px, 24px, 500);
$headline5: mat.define-typography-level(22px, 24px, 700); // H1
$headline6: mat.define-typography-level(16px, 24px, 500); // H2
$subtitle1: mat.define-typography-level(16px, 24px, 400); // H3
$subtitle2: mat.define-typography-level(14px, 24px, 500); // H4
$body1: mat.define-typography-level(15px, 24px, 400); // Base body text
$body2: mat.define-typography-level(14px, 24px, 400);
$body3: mat.define-typography-level(13px, 24px, 400);
$overline: mat.define-typography-level(11px, 24px, 400);
$caption: mat.define-typography-level(12px, 24px, 400);
$button: mat.define-typography-level(15px, 24px, 400);
// $input: mat.define-typography-level(14px, 24px, 400);

// Define the typography config.
$rita-typography: mat.define-typography-config(
  $font-family: 'Nunito',
  $headline-1: $headline1,
  $headline-2: $headline2,
  $headline-3: $headline3,
  $headline-4: $headline4,
  $headline-5: $headline5,
  $headline-6: $headline6,
  $body-1: $body1,
  $body-2: $body2,
  $subtitle-1: $subtitle1,
  $subtitle-2: $subtitle2,
  $button: $button,
  $caption: $caption,
  $overline: $overline
);

$rita-web-primary: mat.define-palette($rita-primary, 700);
$rita-web-accent: mat.define-palette($rita-accent, A200, A100, A400);
$rita-web-warn: mat.define-palette($rita-warn);
//$rita-web-yellow: mat.define-palette($rita-toastr, yellow-1);

$rita-web-theme: mat.define-light-theme((
  color: (
    primary: $rita-web-primary,
    accent: $rita-web-accent,
    warn: $rita-web-warn,
    alert: map-get($rita-toastr, yellow-1),
  ),
  typography: $rita-typography,
  density: -2,
));

@include mat.all-component-themes($rita-web-theme);
@include mat.all-component-typographies($rita-typography);
//@include mat.typography-hierarchy($rita-typography);

$scroll-color: var(--rita-black-color-300);
$scroll-background: var(--rita-background-color-1);
$button-height: 48px;

/* button default styles */
.mdc-button {

  &.mat-mdc-button-base {
    height: $button-height;
  }

  &.mat-primary {
    &.mdc-button, &.mdc-button--unelevated {
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 8px;

      // &:hover {
      //   background-color: var(--rita-primary-color-200) !important;
      // }

      &:disabled {
        color: #CDCDCD !important;
        background-color: #F5F5F5 !important;
      }

      // &:active, &:focus {
      //   background-color: var(--rita-primary-color-200) !important;
      // }
    }

    &:disabled {
      color: var(--rita-primary-color-200);
    }

    &.mat-mdc-raised-button {
      &:disabled {
        background-color: #2ba16899;
      }
    }

    &.mat-mdc-outlined-button {
      &:not(:disabled) {
        border-color: var(--rita-system-color-primary);
        background-color: #F4FCFB;
      }
    }
  }

  &.mat-warn {
    &.mdc-button, &.mdc-button--unelevated {
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 8px;
    }

    &.mat-mdc-outlined-button {
      border-color: var(--rita-misc-color-red-7);

      &:disabled {
        border-color: var(--rita-misc-color-red-4);
        color: var(--rita-misc-color-red-4);
      }
    }
  }

  &.mat-alert {
    &.mdc-button, &.mdc-button--unelevated {
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 8px;
    }
    &.mat-mdc-outlined-button {
      border-color: var(--rita-toastr-color-yellow-1);
      color: var(--rita-toastr-color-yellow-1);

      &:disabled {
        border-color: var(--rita-toastr-color-yellow-2);
        color: var(--rita-toastr-color-yellow-2);
      }
    }
  }

  &.mat-orange {
    &.mdc-button, &.mdc-button--unelevated {
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 8px;
    }

    &.mat-mdc-outlined-button {
      color: #F29D53;
      border-color: #F29D53;
      background: #FFFBF4;
    }
  }
}

/* Estilos personalizados para el tooltip */
.mat-tooltip {
  background-color: #333;  // Cambia el color de fondo
  color: red !important;           // Cambia el color del texto
  border-radius: 4px;    // Añade bordes redondeados
  padding: 8px;          // Ajusta el relleno interno
  font-size: 14px;       // Ajusta el tamaño de la fuente
  border: 1px solid #666; // Añade un borde
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Añade una sombra
}

/* Estilos adicionales para el flechazo del tooltip */
.mat-tooltip-arrow {
  background-color: #333;  // Cambia el color de fondo
}

.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 16px;
  line-height: 1.5;
}

/* Input */
.pure-material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.pure-material-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pure-material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox > input:focus {
  opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
  opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}

.mdc-snackbar{
  &.snackbar-info {
    border-radius: 8px;
    border: var(--rita-toastr-color-blue-1) 1px solid!important;
    .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
      max-width: 520px;
      background-color: var(--rita-toastr-color-blue-2)!important;
      color: var(--rita-toastr-color-blue-1)!important;
      border-radius: 8px!important;
    }
    .toast-title .toast-text {
      color: var(--rita-toastr-color-blue-1)!important;
    }
  }

  &.snackbar-warning {
    border-radius: 8px;
    border: var(--rita-toastr-color-yellow-1) 1px solid!important;
    background-color: var(--rita-toastr-color-yellow-2)!important;
    color: var(--rita-toastr-color-yellow-1)!important;
    .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
      max-width: 520px;
      background-color: var(--rita-toastr-color-yellow-2)!important;
      color: var(--rita-toastr-color-yellow-1)!important;
      border-radius: 8px!important;
    }
    .toast-title .toast-text {
      color: var(--rita-toastr-color-yellow-1)!important;
    }
  }

  &.snackbar-success {
    border-radius: 8px;
    border: var(--rita-toastr-color-green-1) 1px solid!important;
    background-color: var(--rita-toastr-color-green-2)!important;
    color: var(--rita-toastr-color-green-1)!important;
    .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
      max-width: 520px;
      background-color: var(--rita-toastr-color-green-2)!important;
      color: var(--rita-toastr-color-green-1)!important;
      border-radius: 8px!important;
    }
    .toast-title .toast-text {
      font-family: mat.font-family($rita-typography);
      color: var(--rita-toastr-color-green-1)!important;
    }
  }

  &.snackbar-error {
    border-radius: 8px;
    border: var(--rita-toastr-color-red-1) 1px solid!important;
    background-color: var(--rita-toastr-color-red-3)!important;
    color: var(--rita-toastr-color-red-2)!important;
    .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
      max-width: 520px;
      background-color: var(--rita-toastr-color-red-3)!important;
      color: var(--rita-toastr-color-red-2)!important;
      border-radius: 8px!important;
    }
    .toast-title{
      margin-bottom: 5px !important;
    }
    .toast-text {
      line-height: 16px !important;
      text-align: justify !important;
    }
  }
}

.picking-web-cam {
  .mat-mdc-dialog-surface {
    background-color: #070707 !important;
  }
}


