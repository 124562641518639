// estilos generales para todos los componentes

.mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: #0A806A !important;
}

.mat-expansion-panel 
.mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), 
.mat-expansion-panel 
.mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), 
.mat-expansion-panel:not(.mat-expanded) 
.mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: none !important;
}

.mat-expansion-panel-body {
  padding: 0 0 16px 0 !important;
}

// fin de estilos generales para todos los componentes


// estilos para el componente customer-order

.order {
  .mat-expansion-panel-body {
    padding: 0 16px 16px 16px !important;
  }
}

.order--rp {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #7125EE !important;
  }
}

.order--tpsa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FB3449 !important;
  }
}

.order--hpsa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FF6E00 !important;
  }
}

.order--hesa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FF6E00 !important;
  }
}

.order--spsa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #F1141E !important;
  }
}

// fin de estilos para el componente customer-order

// estilos para el componente customer-attention-finish

.attention-finish--tpsa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FB3449 !important;
  }
}

.attention-finish--hpsa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FF6E00 !important;
  }
}

.attention-finish--hesa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FF6E00 !important;
  }
}

.attention-finish--spsa {
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: #FB3449 !important;
  }
}

// estilos para el componente customer-attention-finish


// estilos para el componente attention-detail

.attention-detail__purchase {
  .mat-expansion-panel-body {
    padding-bottom: 0 !important;
  }
}
// fin estilos para el componente attention-detail


// implementar la nueva plataforma HORUS al 100%
