// estilos generales para todos los componentes

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 16px;
}

.mat-mdc-checkbox
.mdc-checkbox
.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #393939 !important;
}

.mat-mdc-checkbox
.mdc-checkbox
.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #0A806A !important;
}

.mat-mdc-checkbox
.mdc-checkbox
.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox
.mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox
.mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #0A806A !important;
  background-color: #0A806A !important;
}

// fin de estilos generales para todos los componentes

.order {
  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #0A806A !important;
  }

  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #0A806A !important;
    background-color: #0A806A !important;
  }
}

.order--rp {
  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #7125EE !important;
  }

  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #7125EE !important;
    background-color: #7125EE !important;
  }
}

.order--tpsa {
  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #FB3449 !important;
  }

  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #FB3449 !important;
    background-color: #FB3449 !important;
  }
}

.order--hpsa {
  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #FF6E00 !important;
  }

  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #FF6E00 !important;
    background-color: #FF6E00 !important;
  }
}

.order--hesa {
  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #FF6E00 !important;
  }

  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #FF6E00 !important;
    background-color: #FF6E00 !important;
  }
}

.order--spsa {
  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #F1141E !important;
  }

  .mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #F1141E !important;
    background-color: #F1141E !important;
  }
}

// estilos para el componente customer-order
