.mat-mdc-snack-bar-container
.mdc-snackbar__label {
  color: #FFF !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
}

.snack-bar--rp {
  .mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #7125EE !important;
  }
  .mdc-button__label {
    color: #7125EE !important;
  }
}

.snack-bar--tpsa {
  .mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #FB3449 !important;
  }
  .mdc-button__label {
    color: #FB3449 !important;
  }
}

.snack-bar--hpsa {
  .mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #FF6E00 !important;
  }
  .mdc-button__label {
    color: #FF6E00 !important;
  }
}

.snack-bar--hesa {
  .mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #FF6E00 !important;
  }
  .mdc-button__label {
    color: #FF6E00 !important;
  }
}

.snack-bar--spsa {
  .mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #F1141E !important;
  }
  .mdc-button__label {
    color: #F1141E !important;
  }
}
